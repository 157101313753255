import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Drywall() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Drywall Estimation</h2>
            <div><p>Home / Services / Drywall Estimation</p></div>
          </div>

          <div className='service-container' id = 'background-drywall' >
            <div className='ser-flex'>
              <div>
                <h4>Drywall Estimation Service: Precision in every sheet</h4>
                <p>At Surya Group, we provide detailed and accurate drywall estimation services that help you manage material costs, labor, and installation schedules for any project. Whether it is a residential, commercial, or industrial project, we ensure that you have a precise understanding of the drywall materials and labor needed, keeping your projects on track and within budget.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What We Offer: </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff:</h5>
              <p>Our drywall takeoff services offer a meticulous breakdown of all materials required for your project, ensuring that every detail is accounted for. This includes:
                <ul>
                  <li>Drywall sheets (various thicknesses and sizes)</li>
                  <li>Joint compounds, tape, corner beads, and fasteners</li>
                  <li>Soundproofing, fire-rated, or moisture-resistant drywall as needed.</li>
                  <li>Drywall accessories like trims and mouldings.</li>
                </ul>
              </p>
            </div>
            
            <div>
              <h5>2. Accurate Area Measurement:</h5>
              <p>We calculate the exact surface area of walls, ceilings, and other structures, providing precise estimates for the drywall sheets needed. Our measurement services include:
                <ul>
                  <li>Room-by-room wall and ceiling measurements</li>
                  <li>Staircases, soffits, and bulkheads</li>
                  <li>Windows, doors, and other cutout areas</li>
                  <li>Multi-story or complex building configurations</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation:</h5>
              <p>Our service provides a detailed estimate of labor costs, including:
                <ul>
                  <li>Hours required for drywall installation, taping, mudding, and sanding.</li>
                  <li>Labor for special tasks, such as curved surfaces, intricate layouts, or fire-rated installations</li>
                  <li>Cost projections for finishing work, ensuring a smooth, polished result.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support:</h5>
              <p>We offer clear, detailed budget breakdowns, ensuring that your drywall project stays on target financially. Our budgeting services include:
                <ul>
                  <li>Itemized material and labor costs.</li>
                  <li>Cost-saving strategies to maximize efficiency without compromising quality.</li>
                  <li>Professional client quotations, ensuring transparency and clarity in pricing.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Tracking:</h5>
              <p>We provide organized, professional invoicing that keeps your project finances on track. Our invoicing services include:
                <ul>
                  <li>Itemized lists of all drywall materials, fasteners, and accessories</li>
                  <li>Ongoing cost tracking to manage material usage and avoid overspending.</li>
                  <li>Professional invoices to simplify monetary management.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Why Choose Our Drywall Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Accuracy & Detail: </strong> We provide reliable, detailed estimates that include every sheet of drywall and all associated materials, ensuring you have exactly what is needed.</li>
                  <li><strong>Time-Saving Expertise: </strong> We manage the complex task of drywall estimation, allowing you to focus on managing your project efficiently.</li>
                  <li><strong> Cost Efficiency:</strong> Our precise takeoffs reduce material waste and help keep your project within budget.</li>
                  <li><strong> Custom Solutions: </strong> Our estimates are tailored to meet the specific needs of your project, whether it is a simple drywall installation or a more complex architectural design.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our Drywall Estimation Services?</h5>
              <p>
                <ul>

                  {/* FROM HERE */}
                  <li><strong>General Contractors: </strong>Ensure smooth project execution with accurate drywall estimates that keep your job on schedule and within budget.</li>
                  <li><strong>Subcontractors: </strong> Simplify your workflow with detailed material takeoffs and labor cost estimates, allowing you to focus on expert installation.</li>
                  <li><strong>Project Managers: </strong>Maintain control over material usage and labor costs with reliable and transparent estimates.</li>
                  <li><strong>Developers:  </strong>Keep your drywall projects on budget and on schedule with detailed material and labor cost projections for all building types.</li>
                  <li><strong>Architects:  </strong>Ensure your designs are accurately translated into reliable material estimates and installation plans.</li>
                  <li><strong>Engineers: </strong> Receive precise measurements and material requirements that align with structural and safety specifications.</li>
                  <li><strong>Quantity Surveyors: </strong>  Enhance your cost analysis with accurate breakdowns of drywall materials and labor, ensuring effective project cost management.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Get Started Today!</h5>
              <p>
              Let us take care of your drywall estimation needs so you can focus on delivering top-quality results. Contact us today for a <strong>free consultation</strong>  and discover how our expert drywall estimation services can streamline your next project. 
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
