import React, { useState } from 'react';

import '../../styles/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to an API
        console.log('Form submitted:', formData);
    };

  return (
    <section>
        <div className='main-width'>


            <div className='com-background' id='contact-back'>
                <div className='com-container'>
                    <h1>Contact Us</h1>
                    <p>Home / Contact Us</p>

                </div>
            </div>

            
                <div className='contact-menu'>

                    <div className='contact-inner'>
                        <div className = 'com-logo'><FontAwesomeIcon icon={faEnvelope} /></div>  
                        <h4>Email (Landline) </h4>    
                        <a class=" nav-link link-btn " href="mailto:Info@Estimation.us">Info@suryagroup.us</a>
                
                    </div>
                    
                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faPhone} /></div>
                        <h4>Call (Landline) </h4>
                        <a class=" nav-link link-btn " href="tel:+919634377090">+91 9634377090</a>
                        <a class=" nav-link link-btn " href="tel:+918077414663">+91 8077414663</a>

                    </div>

                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faLocationDot} /></div>
                        <h4>Our Office Location</h4>
                        <address>
                            012 Main Street, <br/>Bageshwar,<br/> Uttarakhand,<br/> 263642,<br/> India
                        </address>

                    </div>
                </div>

                <div className='contact-form'>
                    <div>
                        <div>
                            <h2>Get In Touch</h2>
                            <p>Send Us your query, we will reply you in next 24 hour!</p>
                            <p>Ask how we help you:</p>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h2>1-232-342-3456</h2>
                                    <p>Monday - Saturday</p>
                                    <p>24x7 Hour Available</p>
                                </div>
                                <h2>SEND A MESSAGE</h2>
                                <div>
                                    <a href='#' target='__blank'>insta</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="message">Message:</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            

        </div>
    </section>
  )
}
