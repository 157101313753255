import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Masonry() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Masonry Estimation</h2>
            <div><p>Home / Services / Masonry Estimation</p></div>
          </div>

          <div className='service-container' id = 'background-masonry' >
            <div className='ser-flex'>
              <div>
                <h4>Masonry Estimation Service: Building with precision and Expertise</h4>
                <p>At Surya Group, we provide precise and reliable masonry estimation services to help you plan, budget, and execute your masonry projects with accuracy. Whether your project involves brickwork, stone, block, or other masonry materials, we deliver detailed material takeoffs, labor projections, and cost estimates to ensure smooth project completion.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What We Offer:</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Comprehensive Material Takeoff:</h5>
              <p>Our masonry estimation service includes an accurate breakdown of all materials required for your project. We cover:
                <ul>
                  <li>Bricks, blocks, and stones (distinct types and sizes)</li>
                  <li>Mortar, grout, and reinforcement materials.</li>
                  <li>Wall ties, flashing, and waterproofing components.</li>
                  <li>Specialized masonry products for decorative or structural purposes.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>2. Accurate Area Measurement:</h5>
              <p>We calculate the exact surface area for all masonry work, ensuring you have the right amount of material for each section. Our measurements include:
                <ul>
                  <li>Wall surface areas for brick, block, or stone.</li>
                  <li>Chimneys, columns, and decorative masonry features.</li>
                  <li>Openings for windows, doors, and other architectural elements</li>
                  <li>Complex structures such as arches, retaining walls, and garden walls.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation:</h5>
              <p>Our detailed labor estimates ensure that your masonry project runs efficiently and on budget. We provide:
                <ul>
                  <li>Labor hours for preparation, laying, and finishing masonry.</li>
                  <li>Specialized labor costs for intricate or custom masonry designs.</li>
                  <li>Labor for tasks such as reinforcement, lintel installation, and waterproofing.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support:</h5>
              <p>We offer comprehensive budgeting services, ensuring that your masonry project stays financially on track. Our services include:
                <ul>
                  <li>Detailed material and labor cost breakdowns</li>
                  <li>Suggestions for cost-saving strategies to maximize efficiency.</li>
                  <li>Customizable quotations for clients, ensuring transparency and trust.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Cost Tracking:</h5>
              <p>Our professional invoicing services help you keep track of materials and costs throughout the project. We offer:
                <ul>
                  <li>Itemized lists of all masonry materials and accessories</li>
                  <li>Ongoing material cost tracking to ensure you stay within budget.</li>
                  <li>Professional invoicing to simplify financial management.</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Choose Our Masonry Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Precision & Accuracy:   </strong> Our estimates ensure that every brick, block, and stone is accounted for, providing you with a reliable and complete breakdown of materials and labor.</li>
                  <li><strong>Time-Saving Solutions:</strong> We manage the complexity of masonry estimation, giving you more time to focus on the actual construction.</li>
                  <li><strong>Cost Efficiency: </strong> Our detailed takeoffs and labor projections help you avoid waste and unnecessary expenses, keeping your project within budget.</li>
                  <li><strong>Customized Services:  </strong> Whether it is a small residential project or a large commercial structure, our estimates are tailored to the unique requirements of your masonry work.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our Masonry Estimation Services?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> · General Contractors: Ensure smooth project management with precise material and labor estimates for all masonry aspects.</li>
                  <li><strong>Subcontractors: </strong> Simplify your workflow with accurate material takeoffs and labor projections, allowing you to focus on delivering high-quality masonry work.</li>
                  <li><strong>Project Managers: </strong> Maintain control over material usage and labor costs with transparent and reliable estimates.</li>
                  <li><strong>Developers: </strong> Keep your masonry projects on budget and on schedule with comprehensive material and labor cost breakdowns.</li>
                  <li><strong>Architects:  </strong> Ensure your masonry designs are brought to life with accurate material estimates and installation requirements.</li>
                  <li><strong>Engineers:  </strong>Receive precise calculations for structural masonry components and reinforcement requirements.</li>
                  <li><strong>Quantity Surveyors:</strong> Enhance your cost analysis with detailed breakdowns of masonry materials and labor, ensuring effective project valuations.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Get Started Today!</h5>
              <p>Let us handle the intricate details of your masonry estimation, so you can focus on building strong, beautiful structures. Contact us today for a <strong> free consultation</strong> and discover how our expert masonry estimation services can benefit your next project.
</p>
            </div>
          </div>  

        </div>
    </section>  
  )
}
