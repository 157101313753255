import React, { useState } from "react";
// import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './../../styles/ScheduleMeeting.css';

const ScheduleMeeting = () => {
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingDate, setMeetingDate] = useState(null);
  const [meetingDescription, setMeetingDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!meetingTitle || !meetingDate || !meetingDescription) {
      alert("Please fill out all fields");
      return;
    }

    console.log({
      title: meetingTitle,
      date: meetingDate,
      description: meetingDescription,
    });

    setMeetingTitle("");
    setMeetingDate(null);
    setMeetingDescription("");
    alert("Meeting Scheduled Successfully!");
  };

  return (
   
      <section><div className="main-width">
        <div className="meeting-page">
          <div className="book-meeting " id="login-list">
            <h2>Schedule meeting according your timing</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet reiciendis velit asperiores beatae temporibus quae magni sit laudantium debitis officiis praesentium quidem repudiandae consectetur, quam vero eaque et voluptates ea!
            </p>
            {/* <Link className="cmn-btn cmn-btn-light" to = "#!">Join Us</Link> */}
          </div>
          
          
          <div className="book-meeting login-content" style={{alignItems: "center"}}>
            <div className="login-item ">
              <h2>Schedule a Meeting</h2>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Meeting Title:</label>
                  <input
                    className="meet-input"
                    type="text"fd
                    value={meetingTitle}
                    onChange={(e) => setMeetingTitle(e.target.value)}
                    placeholder="Enter Meeting Title"
                    required
                  />
                </div>
                <div >
                  <label>Meeting Date & Time:</label>
                  <DatePicker
                    className="meet-input"
                    selected={meetingDate}
                    onChange={(date) => setMeetingDate(date)}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Select Date & Time"
                    required
                  />
                </div>
                <div>
                  <label>Meeting Description:</label>
                  <textarea
                    className="meet-input"       
                    value={meetingDescription}
                    onChange={(e) => setMeetingDescription(e.target.value)}
                    placeholder="Enter Meeting Description"
                    required
                  />
                </div>
                <div id="login-btn-out">
                  <button className="cmn-btn cmn-btn-dark" type="submit">Schedule Meeting</button>

                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </section>
    
    
  );
};

export default ScheduleMeeting;
