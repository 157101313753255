// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Error: Page Not Found</h1>
    </div>
  );
};

export default NotFound;
