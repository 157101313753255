import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'; // Ensure this is the correct package


import '../../../styles/services/global_service.css'
// import "../../../styles/services/unique_service's_css/Flooring.css"

export default function Flooring() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Flooring Estimation</h2>
            <div><p>Home / Services / Flooring Estimation</p></div>
          </div>

          <div className='service-container' id = 'background-flooring' >
            <div className='ser-flex'>
              <div>
                <h4>Flooring Estimation Service: Precision from the Ground Up</h4>
                <p>At Surya Group, our flooring estimation services are designed to provide accurate, comprehensive takeoffs and budgeting for all types of flooring projects. Whether you're working on residential homes, commercial properties, or large-scale developments, our team ensures that you receive precise estimates for all flooring materials and installation costs, helping you complete your projects efficiently and within budget.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What We Offer:</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Detailed Material Takeoff:</h5>
              <p>We offer precise material takeoff services for a variety of flooring types, ensuring that your project has the exact quantities needed for a seamless installation. Our takeoff includes:
                <ul>
                  <li>Hardwood, laminate, vinyl, tile, carpet, and specialty flooring</li>
                  <li>Underlayment materials</li> 
                  <li>Adhesives, grouts, and sealants</li>
                  <li>Flooring trims, mouldings, and accessories</li>
                </ul>
              </p>
            </div>
            
            <div>
              <h5>2. Accurate Area Measurement:</h5>
              <p>Our team uses advanced tools and techniques to calculate the exact surface area for flooring, ensuring that every square foot is accounted for. We include:
                <ul>
                  <li>Room-by-room measurements</li>
                  <li>Floor layouts and patterning (herringbone, diagonal, etc.)</li>
                  <li>Staircases, landings, and multi-level calculations</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation:</h5>
              <p>In addition to materials, our flooring estimates include labor cost projections, tailored to the complexity of the installation:
                <ul>
                  <li>Skilled labor hours for floor preparation, installation, and finishing</li>
                  <li>Specialized labor for custom flooring designs and intricate layouts</li>
                  <li>Clear breakdown of costs for each phase of the flooring project</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support:</h5>
              <p>Our flooring estimates include detailed cost breakdowns to help you manage your project budget effectively. We provide:
                <ul>
                  <li>Itemized material and labor costs</li>
                  <li>Budgeting support to avoid overspending on materials or labor</li>
                  <li>Professional quotations for your clients, ensuring transparency and accuracy</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Tracking:</h5>
              <p>We help you stay organized and on track with clear invoicing and ongoing material cost management:
                <ul>
                  <li>Itemized lists of all flooring materials</li>
                  <li>Pricing and cost tracking throughout the project</li>
                  <li>Professional invoices for easy financial management.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Why Choose Our Flooring Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>Accuracy You Can Trust: </strong> With precise material takeoffs and detailed area measurements, you'll have everything you need to execute a flawless flooring project.</li>
                  <li><strong>Time-Saving Expertise: </strong>Our team handles the complexity of material and labor estimates, freeing up your time to focus on project execution.</li>
                  <li><strong>Cost Efficiency:</strong> Our detailed budgeting helps you optimize material usage, reducing waste and keeping your project within budget.</li>
                  <li><strong>Tailored Solutions: </strong> From residential to commercial flooring, we provide custom estimates that fit the specific demands of your project.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our Flooring Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong>General Contractors: </strong> Ensure efficient project management with reliable flooring material and labor estimates that align with your construction timelines.</li>
                  <li><strong>Subcontractors: </strong> Simplify your workflow with accurate flooring material takeoffs and cost estimates, allowing you to focus on expert installation.</li>
                  <li><strong>Project Managers: </strong>  Gain full control over your flooring project's resources and costs with precise and transparent estimates.</li>
                  <li><strong>Developers: </strong>  Keep your projects on budget and on schedule with professional estimates that provide a clear path from material procurement to installation.</li>
                  <li><strong>Architects: </strong>Ensure your design visions are realized with detailed material estimates and cost projections that meet both aesthetic and functional goals.</li>
                  <li><strong>Engineers:  </strong>Receive accurate surface area calculations and material requirements that align with structural plans and specifications.</li>
                  <li><strong>Quantity Surveyors: </strong> Enhance your cost analysis with detailed breakdowns of flooring materials and labor, ensuring accurate project valuations.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Get Started Today!</h5>
              <p>
              Let us handle the intricacies of your flooring estimation process so you can focus on delivering exceptional craftsmanship. Contact us today for a <strong>free consultation</strong>  and see how our professional flooring estimation services can benefit your next project.
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
