import React from 'react'
import { Link } from 'react-router-dom';

import '../../styles/Home.css'

import img1 from '../../assets/images/carousel1.png'
import img2 from '../../assets/images/carousel2.png'
import img3 from '../../assets/images/carousel3.png'

export default function Home() {
  return (
    <section>
      <div className='main-width'>
      <div id="carouselExampleRide" className="carousel slide" data-bs-ride="true">
        <div className="carousel-inner">

            <div className="carousel-height carousel-item active">
                <img src={img1} alt="loading" />
              <div className='carousel_element'>
                <h1>Welcome to Surya Group</h1>
                <p>At Surya Group, we provide clear and accurate construction estimates with a personal touch. Using the latest tools and technology, we make your project planning and budgeting easy and precise. Let us help you bring your vision to life with reliable and innovative solutions.</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/about">Learn More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/meeting/schedule">Schedule a Meeting</Link>

                </div>
              </div>
            </div>

            
            <div className="carousel-height carousel-item ">
              {/* <img src= {carousel1} className="d-block w-100" alt="..."/> */}
              <img src={img3} alt="loading" />
              <div className='carousel_element'>
                <h1>Bringing Precision to Your Projects</h1>
                <p>Accurate Construction Estimates | On-Time Budgeting Solutions</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Learn More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Schedule a Meeting</Link>

                </div>
              </div>
            </div>

            <div className="carousel-height carousel-item ">
              {/* <img src= {carousel1} className="d-block w-100" alt="..."/> */}
              <img src={img2} alt="loading" />
              <div className='carousel_element'>
                <h1>From Concept to Completion</h1>
                <p>Expert Estimating Services Tailored for Contractors</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Learn More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Schedule a Meeting</Link>

                </div>
              </div>
            </div>

            <div className="carousel-height carousel-item ">
              {/* <img src= {carousel1} className="d-block w-100" alt="..."/> */}
              <img src={img3} alt="loading" />
              <div className='carousel_element'>
                <h1>Speed Meets Accuracy</h1>
                <p>Efficient, Reliable Estimates to Keep Your Projects on Track</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Learn More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Schedule a Meeting</Link>

                </div>
              </div>
            </div>

            <div className="carousel-height carousel-item ">
              {/* <img src= {carousel1} className="d-block w-100" alt="..."/> */}
              <img src={img1} alt="loading" />
              <div className='carousel_element'>
                <h1>Tailored Solutions for Every Build</h1>
                <p>Customized Construction Estimations for Residential, Commercial, and Infrastructure Projects</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Learn More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Schedule a Meeting</Link>

                </div>
              </div>
            </div>

            <div className="carousel-height carousel-item ">
              {/* <img src= {carousel1} className="d-block w-100" alt="..."/> */}
              <img src={img2} alt="loading" />
              <div className='carousel_element'>
                <h1>Ready to Build the Future?</h1>
                <p>Contact Us for a Consultation Today!</p>
                <div>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Read More</Link>
                  <Link className='cmn-btn cmn-btn-dark' to= "/#">Sign In</Link>

                </div>
              </div>
            </div>


          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className='section1'>
            <h2>SERVICES</h2>
          <div id='service'>
            <div className='service-item' id='Lumber-est'>
              <div>
                <h3>Lumber Estimation</h3>
                <p>We provide a precise count of every piece of lumber needed, ensuring that your project is supplied with exactly the right amount. This not only helps reduce material waste but also keeps your project within budget. Our detailed quantity calculations include:
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Lumber Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item' id='flooring-est'>
              <div>
                <h3>Flooring Estimation</h3>
                <p>We offer precise material takeoff services for a variety of flooring types, ensuring that your project has the exact quantities needed for a seamless installation. Our takeoff includes:.
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Flooring Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='Painting-est'>
              <div>
                <h3>Painting Estimation</h3>
                <p>Our painting takeoff services provide an exact calculation of all materials required, ensuring you have the right amount of paint and supplies for every surface. We include.
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Painting Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='Siding-est'>
              <div>
                <h3>Siding Estimation</h3>
                <p>We meticulously analyze project blueprints to offer a comprehensive breakdown of Siding materials required, ensuring every piece is accounted for. Our takeoff services cover.
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Siding Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='Roofing-est'>
              <div>
                <h3>Roofing Estimation</h3>
                <p>Our roofing takeoff services provide a thorough breakdown of all materials required for your project, ensuring that every component is accounted for. We.
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Roofing Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='MEP-est'>
              <div>
                <h3>MEP Estimation</h3>
                <p>Our MEP estimation services include a detailed breakdown of all materials needed for the mechanical, electrical, and plumbing components of your project.
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/MEP Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='Electrical-est'>
              <div>
                <h3>Electrical Estimation</h3>
                <p> We're here to ensure your electrical projects are powered up with accuracy, efficiency, ..
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Electrical Estimation">Read More</Link>
              </div>
            </div>
            <div className='service-item'id='Hardware-est'>
              <div>
                <h3>Openings & Hardware</h3>
                <p>we turn the complexity of openings and hardware estimation into a masterpiece of precision! Whether you're dealing with doors that ...
                </p>
                <Link className='cmn-btn cmn-btn-dark' to = "/services/Openings & Hardware">Read More</Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
  )
}
