import React from 'react';
import '../src/styles/Global.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './features/auth/Login';
import Home from './pages/home/Home'; // Importing the page components
import About from './pages/about/About';
import Footer from './components/Footer';
import NotFound from './components/Notfound';
import ScheduleMeeting  from './pages/meeting/ScheduleMeeting';
import GnContractor from './pages/services/gernal_contractor/GnContractor';
import Contact from './pages/contact/Contact';
import ChatBot from './components/ChatBot';
import Flooring from './pages/services/sub_contractor/Flooring'
import Drywall from './pages/services/sub_contractor/Drywall'
import Painting from './pages/services/sub_contractor/Painting'
import Siding from './pages/services/sub_contractor/Siding';
import Lumber from './pages/services/sub_contractor/Lumber';
import Concrete from './pages/services/sub_contractor/Concrete';
import Masonry from './pages/services/sub_contractor/Masonry';
import Roofing from './pages/services/sub_contractor/Roofing';
import Insulation from './pages/services/sub_contractor/Insulation';
import Demolition from './pages/services/sub_contractor/Demolition';
import Electrical from './pages/services/sub_contractor/Electrical';
import Mep from './pages/services/sub_contractor/Mep';
import Opening from './pages/services/sub_contractor/Opening';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LayoutWithNavbarFooter />}>

{/* for checking url that exist or not */}
          <Route path="/login" element={<Login />} />   
          <Route path="/meeting/schedule" element={<ScheduleMeeting  />} />   
          <Route path='/general-contractor' element= {<GnContractor/>}/>
          <Route path='/contact-us' element = {<Contact/>}/>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path = '/services/Flooring Estimation' element = {<Flooring/>} />
          <Route path = '/services/Drywall Estimation' element = {<Drywall/>} />
          <Route path = '/services/Painting Estimation' element = {<Painting/>} />
          <Route path = '/services/Siding Estimation' element = {<Siding/>} />
          <Route path = '/services/Lumber Estimation' element = {<Lumber/>} />
          <Route path = '/services/Concrete Estimation' element = {<Concrete/>} />
          <Route path = '/services/Masonry Estimation' element = {<Masonry/>} />
          <Route path = '/services/Roofing Estimation' element = {<Roofing/>} />
          <Route path = '/services/Insulation & Moisture Protection' element = {<Insulation/>} />
          <Route path = '/services/Demolition Estimation' element = {<Demolition/>} />
          <Route path = '/services/Electrical Estimation' element = {<Electrical/>} />
          <Route path = '/services/MEP Estimation' element = {<Mep/>} />        
          <Route path = '/services/Openings & Hardware' element = {<Opening/>} />

        </Route>
        <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
      </Routes>
    </Router>
  );
}

// Layout component that includes Navbar and Footer
function LayoutWithNavbarFooter() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/general-contractor' element={<GnContractor />}/>
        <Route path="/login" element={<Login />} />
        <Route path='/contact-us' element = {<Contact/>}/>
        <Route path='/meeting/schedule' element={<ScheduleMeeting  />}/>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path = '/services/Flooring Estimation' element = {<Flooring/>} />
        <Route path = '/services/Drywall Estimation' element = {<Drywall/>} />
        <Route path = '/services/Painting Estimation' element = {<Painting/>} />
        <Route path = '/services/Siding Estimation' element = {<Siding/>} />
        <Route path = '/services/Lumber Estimation' element = {<Lumber/>} />
        <Route path = '/services/Concrete Estimation' element = {<Concrete/>} />
        <Route path = '/services/Masonry Estimation' element = {<Masonry/>} />
        <Route path = '/services/Roofing Estimation' element = {<Roofing/>} />
        <Route path = '/services/Insulation & Moisture Protection' element = {<Insulation/>} />
        <Route path = '/services/Demolition Estimation' element = {<Demolition/>} />
        <Route path = '/services/Electrical Estimation' element = {<Electrical/>} />
        <Route path = '/services/MEP Estimation' element = {<Mep/>} />
        <Route path = '/services/Openings & Hardware' element = {<Opening/>} />

      </Routes>
      <ChatBot/>
      <Footer />
    </>
  );
}

export default App;
