import React from 'react'
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { createChatBotMessage } from 'react-chatbot-kit';
import axios from 'axios';
// \

const config = {
  initialMessages: [createChatBotMessage("Hello! Welcome to Surya Group, How can I help you?")],
};


class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    // Directly pass the message to GPT handler
    this.actionProvider.handleGptResponse(message);
  }
}



class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  // Function to handle GPT API call
  handleGptResponse = async (userMessage) => {
    const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-3.5-turbo", 
          messages: [{ role: "user", content: userMessage }]
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const gptMessage = response.data.choices[0].message.content;
      const message = this.createChatBotMessage(gptMessage);

      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    } catch (error) {
      console.error("Error with GPT API request: ", error);
      const message = this.createChatBotMessage("I'm having trouble processing your request. Please try again later.");
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    }
  };

  // Other default responses can remain as fallback options if you still want them
  handleHello = () => {
    const message = this.createChatBotMessage("Hello, Dear!");
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  handleHowareyou = () => {
    const message = this.createChatBotMessage("I am good! What about you?");
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };

  handleName = () => {
    const message = this.createChatBotMessage("I am Suraj Singh Mehra");
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}



export default function ChatBot() {
  return (
    <>
     <div style={{ display: 'none', position: 'fixed', bottom: 20, right: 20, boxShadow: '0 0 18px -10px #000000', zIndex:5}}>
          <Chatbot 
            config={config} 
            messageParser={MessageParser} 
            actionProvider={ActionProvider} 
          />
        </div>
    </>
  )
}
