import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Opening() {
  return (
    <section>
        <div className='main-width'>

          <div className='heading-sr'>
            <h2>Openings & Hardware Estimation Service</h2>
            <div><p>Home / Services / Openings & Hardware</p></div>
          </div>

          <div className='service-container' id = 'background-flooring' >
            <div className='ser-flex'>
              <div>
                <h4>Openings & Hardware Estimation Service: Where Precision Meets Possibility</h4>
                <p>Welcome to Surya Group, where we turn the complexity of openings and hardware estimation into a masterpiece of precision! Whether you're dealing with doors that make grand entrances, windows that frame perfect views, or hardware that ties it all together, we've got you covered down to the last hinge.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What's Behind Our Estimation Doors?: </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Detailed Material Takeoff: Every Component Counts:</h5>
              <p>We don't just count doors; we account for every element that makes your project swing, slide, or secure:
                <ul>
                  <li> Doors (from cozy interiors to grand entrances, fire-rated fortresses to automatic wonders)</li>
                  <li> Windows (standard stunners, custom creations, skylights that brighten your day, and curtain walls that wow)</li>
                  <li> Hardware heroes: hinges, locks, handles, latches, closers, and security systems (because the devil is in the details)</li>
                  <li> Frames, thresholds, weather stripping, and seals (keeping the elements where they belong - outside!)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>2. Measurements That Would Make Michelangelo Proud</h5>
              <p>We measure once, twice, and then some more to ensure perfection:
                <ul>
                  <li> Precise sizing for every door and window opening (because 'almost fits' never cut it)</li>
                  <li> Hardware quantities calculated with surgical precision</li>
                  <li> Special attention to those tricky custom or oversized openings</li>
                  <li> Extra care for fire-rated and safety-compliant openings (because some doors do more than just open and close)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor & Installation Cost Estimation: Accounting for Every Swing and Click</h5>
              <p>We don't just tally materials; we calculate the craftsmanship:
                <ul>
                  <li> Labor hours for door and window installation (including the art of frame setup)</li>
                  <li> Hardware installation, from simple hinges to high-tech automated systems</li>
                  <li> Specialized labor for those extra-special security and fire-rated systems</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support: Keeping Your Finances as Solid as Your Doors</h5>
              <p>Our budgeting services ensure your project is financially sound and transparent:
                <ul>
                  <li> Detailed cost breakdowns (because knowledge is power, and power opens doors)</li>
                  <li> Value engineering recommendations (optimizing quality without breaking the bank)</li>
                  <li> Crystal-clear quotations (no hidden costs hiding behind any doors here)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Cost Tracking: Clarity in Every Column</h5>
              <p>We keep your project's financial picture as clear as a freshly cleaned window:
                <ul>
                  <li> Itemized lists of all materials (know where every penny swings)</li>
                  <li> Ongoing tracking to keep your budget from going off its hinges</li>
                  <li> Professional invoicing that opens doors to easy financial management</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Knock on Our Door for Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong> Precision & Accuracy:  </strong>  We count every hinge and measure every frame, so you don't have to</li>
                  <li><strong> Time-Saving Expertise:</strong>  Our efficient process gives you more time to focus on bringing your vision to life</li>
                  <li><strong> Cost Efficiency:  </strong>Stay on budget without compromising on quality (have your cake and eat it too!)</li>
                  <li><strong> Tailored Solutions:  </strong> From standard swings to high-security clicks, we customize our estimates to fit your project like a glove</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Gets the Keys to Our Estimation Kingdom?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Keep your projects swinging smoothly from start to finish</li>
                  <li><strong>Subcontractors: </strong>  Focus on installation mastery while we handle the number crunching</li>
                  <li><strong>Project Managers: </strong> Maintain control that's as solid as a well-hung door</li>
                  <li><strong>Developers:   </strong> Keep your projects on track, from foundation to final doorknob polish</li>
                  <li><strong> Architects: </strong>Architects: Bring your grandest opening designs to life, down to the last detail</li>
                  <li><strong>Engineers:</strong>  Align your structural plans with our precise calculations (it's a perfect fit!)</li>
                  <li><strong>Quantity Surveyors:  </strong>Enhance your analysis with our detailed breakdowns (we speak your language)</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Open the Door to Estimation Excellence?</h5>
              <p>
              Let us handle the intricacies of your openings and hardware estimations, so you can focus on building spaces that wow. Contact us today for a free consultation and discover how our expertise can be the key to your project's success.
              Remember, in the world of openings and hardware estimation, we're the perfect blend of locksmith precision and interior designer vision. Let's create something that not only opens - but impresses!
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
