// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // if using react-router

import { Link } from 'react-router-dom';

import "../../styles/Login.css"
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // for redirecting after login

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Optional: Call authentication service to validate user
    // authService.login(email, password);
    
    // Dummy login logic, add your actual login logic
    if (email === 'user@example.com' && password === 'password') {
      navigate('/home/main');  // Redirect to home page after login
    } else {
      alert('Invalid login credentials');
    }
  };

  return (
   
      <section>
        <div className='main-width'>
          <div className='login-menu'> 

            <div className='login-content'>
              <div id='login-list'>
                <h2>Join Surya Group for your best Grouth</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet reiciendis velit asperiores beatae temporibus quae magni sit laudantium debitis officiis praesentium quidem repudiandae consectetur, quam vero eaque et voluptates ea!
                </p>
                <Link className='cmn-btn cmn-btn-light' to = "/meeting/schedule ">Join us</Link>
              </div>
            </div>
            <div className='login-content'>
              <div className='login-item'>
                <h2>Welcome to Surya Group</h2>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>Email</label>
                    <input 
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} 
                      required 
                    />
                  </div>
                  <div>
                    <label>Password</label>
                    <input
                      type='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} 
                      required
                    />
                  </div>
                  <div><Link to="/" className='rmv-decoration'>Forgot password?</Link></div>
                  <div id='login-btn-out'>
                    <button className='cmn-btn cmn-btn-dark' type='submit'>Sign In</button>
                  </div>
                  <div>
                    <p>Don't have an account? <Link to="#!" className='rmv-decoration'>Sign up</Link></p>
                  </div>
                </form>
              </div>
            </div>
          

          </div>
        </div>
      </section>
   
  );
};

export default Login;
