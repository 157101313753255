import React from 'react';
import '../../styles/About.css'
function About() {
  return (
    
    <section>
      <div className='main-width'>
        <div className='com-background' id='about-back'>
          <div className='com-container' >
            <h1>About Us</h1>
            <p>Home / About Us</p>
          </div>
        </div>

        <div className='menu-container'>
          <div className='cmn-container'>
            <div className='text-container'>
              <h2>Who we are?</h2>
              <p>At Surya Group, our name embodies our core values in the construction estimating industry. The term "Surya" reflects clarity, precision, and transparency—hallmarks of our estimation process, ensuring every detail is accurately accessed and communicated. "Group" symbolizes the breadth of our services, encompassing all facets of construction budgeting and estimating. These terms define our commitment to delivering comprehensive, systematic, and explicit cost solutions, empowering clients with data-driven insights for informed project decisions.</p>
            </div>
            <div class="image-container">
              <img src = 'https://static.wixstatic.com/media/245055_719b9ac21e0e47109f8d559af7a23284~mv2.png/v1/fill/w_490,h_343,al_c,lg_1,q_85,enc_auto/245055_719b9ac21e0e47109f8d559af7a23284~mv2.png' alt='description'/>
            </div>
          </div>

          <div className='cmn-container' style={{justifyContent:"end"}}>
            <div class="image-container">
              <img src = 'https://freepngimg.com/thumb/vision/6-2-vision-png-image.png' alt='description'/>
            </div>
            <div className='text-container'>
              <h2>Vision of Surya Group</h2>
              <p>Our vision is to lead the construction industry by delivering top-quality services through cutting-edge technology. By integrating advanced estimating software, AI-driven tools, and innovative plugins, we aim to make project planning and budgeting more efficient, accurate, and accessible. We envision a future where modern construction solutions are seamless, empowering contractors and clients to achieve excellence with precision and clarity in every project.</p>
            </div>
          </div>

          

        </div>

     

       
      </div>
    </section>

  );
}

export default About;

